import React, { useState } from "react";
import {Document, Page, pdfjs} from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const MaisokuDisplay = ({ pdfUrl, onPdfClick }) => {
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <div style={{ position: "relative", width: "100%", overflow: "hidden" }} onClick={() => onPdfClick(pdfUrl)}>
            <Document
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {Array.from(
                    new Array(numPages),
                    (el, index) => (
                        <Page 
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={300}  // あるいは他の任意のサイズ
                            style={{ margin: "0" }}  // ここで縦の間隔を0に設定
                        />
                    )
                )}
            </Document>
        </div>
    );
};

export default MaisokuDisplay;
