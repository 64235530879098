import React, { useState, useEffect } from 'react';
import { Storage, Auth, API } from 'aws-amplify';
import awsconfig from "../aws-exports";
import MaisokuDisplay from "./MaisokuDisplay";
import PdfFullscreenDisplay from './PdfFullscreenDisplay'; // 新しいコンポーネントのインポート
import PdfEditDisplay from './PdfEditDisplay';

const ModalDetails = ({ data, closeModal }) => {


    // 編集用のstateを追加
    const [maisokuUrls, setMaisokuUrls] = useState([]);
    const [fullscreenPdfUrl, setFullscreenPdfUrl] = useState(null);
    const [fullscreenPdfIndex, setFullscreenPdfIndex] = useState(null);

    const [editPdfUrls, setEditPdfUrls] = useState([]);
    const [editPdfUrl, setEditPdfUrl] = useState(null);

    const [editPdfNames, setEditPdfNames] = useState([]);
    const [editPdfName, setEditPdfName] = useState(null);


    const [isEditMode, setIsEditMode] = useState(false);

    const obiName = data.obi_img_name;
    const [refreshKey, setRefreshKey] = useState(0);

    const [editViewport, setEditViewport] = useState(null);

    const [isDownloading, setIsDownloading] = useState(false);

    const handlePdfClick = (pdfUrl, index) => {
        setFullscreenPdfUrl(pdfUrl);
        setFullscreenPdfIndex(index);
        const correspondingEditUrl = editPdfUrls[index];
        const correspondingEditName = editPdfNames[index];
        setEditPdfUrl(correspondingEditUrl);
        setEditPdfName(correspondingEditName);
    };

    const nextPdf = () => {
        const nextIndex = fullscreenPdfIndex + 1;
        if (nextIndex < maisokuUrls.length) {
            handlePdfClick(maisokuUrls[nextIndex], nextIndex);
        }
    };

    const previousPdf = () => {
        const prevIndex = fullscreenPdfIndex - 1;
        if (prevIndex >= 0) {
            handlePdfClick(maisokuUrls[prevIndex], prevIndex);
        }
    };

    const closeFullscreen = () => {
        setFullscreenPdfUrl(null);
        // ステートを更新してコンポーネントを再レンダリング
        setRefreshKey(prevKey => prevKey + 1);
    };

    useEffect(() => {
        // refreshKey が変更されたら再レンダリング後の処理をここに書く
    }, [refreshKey]);


    const handleDownloadClick = async () => {
        setIsDownloading(true);
        try {
            const data = await API.post('stg-download-maisoku-api', '/', {
                body: { 
                    pdf_list: editPdfNames,
                    id_token: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                }
            });
            const presignedUrl = data.presigned_url;
            window.location.href = presignedUrl;
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsDownloading(false);
        }
    };


    useEffect(() => {
        const fetchMaisoku = async (filename) => {
            try {
                const fileUrl = await Storage.get(filename, {
                    bucket: process.env.REACT_APP_OUTPUT_BUCKET_NAME,
                    level: "private",
                    expires: 86400, // URL が有効な秒数（ここでは 86400 秒）
                });
                return fileUrl

            } catch (error) {
                console.error("Error fetching maisoku:", error);
            }
        };

        const fetchAllMaisoku = async () => {
            if (data) {
                let maisokuUrlsTmp = [];
                let editPdfUrlsTmp = [];
                let editPdfNamesTmp = [];
                for(let i = 0; i < data.pdf_num; i++){
                    let base_name = data.pdf_name.split('.')[0]

                    let maisokuFileName = `obigae_${base_name}_${i}_${data.id}.pdf`;
                    maisokuUrlsTmp.push(fetchMaisoku(maisokuFileName));

                    let editFileName = `${base_name}_${i}_${data.id}.pdf`;
                    editPdfUrlsTmp.push(fetchMaisoku(editFileName));
                    editPdfNamesTmp.push(editFileName);
                }
                const resolvedMaisokuUrls = await Promise.all(maisokuUrlsTmp);
                const resolvedEditPdfUrls = await Promise.all(editPdfUrlsTmp);

                setMaisokuUrls(resolvedMaisokuUrls);
                setEditPdfUrls(resolvedEditPdfUrls);
                setEditPdfNames(editPdfNamesTmp); //
            }
        };

        fetchAllMaisoku();
    }, [data, isEditMode]);

    return (
        <div className="modal is-active" key={refreshKey}>{/* key prop に refreshKey を設定 これでrefreshKeyの変更時に再レンダリングされる */}
            {fullscreenPdfUrl && !isEditMode && 
                <PdfFullscreenDisplay 
                    pdfUrl={fullscreenPdfUrl} 
                    onClose={closeFullscreen} 
                    onNext={nextPdf} 
                    onPrevious={previousPdf}
                    onEdit={() => setIsEditMode(true)}
                    setEditViewport={setEditViewport}
                    isEditMode={isEditMode}
                /> 
            }
            {isEditMode && 
                <PdfEditDisplay
                    pdfUrl={editPdfUrl}
                    pdfName={editPdfName}
                    obiName={obiName}
                    onClose={() => setIsEditMode(false)}
                    editViewport={editViewport}
                    pdfClose={closeFullscreen}
                />
            }
            <div className="modal-background" onClick={closeModal}></div>
            <div className="modal-card" style={{ maxWidth: "100%", width: "100%" }}>
                <header className="modal-card-head">
                    <p className="modal-card-title">物件詳細</p>
                    <button className="delete" aria-label="close" onClick={closeModal}></button>
                </header>
                <section className="modal-card-body" style={{ height: "80vh" }}>
                    <div style={{ 
                        display: 'flex', 
                        flexWrap: 'wrap', 
                        justifyContent: 'flex-start' // 左寄せにする
                    }}>
                        {maisokuUrls.map((url, index) => (
                            <div key={index} style={{ 
                                    flex: '0 0 24%', // 4列になるように設定
                                    margin: '0 1% 1% 0' // 縦の間隔と横の間隔を調整
                                }}>
                                <MaisokuDisplay 
                                    key={index}
                                    pdfUrl={url} 
                                    onPdfClick={() => handlePdfClick(url, index)} 
                                />
                            </div>
                        ))}
                    </div>
                    <div>
                        {!fullscreenPdfUrl && !isEditMode &&
                            <button onClick={handleDownloadClick} disabled={isDownloading}>ダウンロード</button>
                        }
                    </div>
                </section>
            </div>
        </div>
    );
};

export default ModalDetails;
