// PdfEditDisplay.js
import React, { useState, useEffect } from "react";
import { Auth, API } from 'aws-amplify';

import { Document, Page, pdfjs } from 'react-pdf';
import { Rnd } from 'react-rnd';
import '../css/PdfDisplay.css'




pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfEditDisplay = ({ pdfUrl, pdfName, obiName, onClose, editViewport, pdfClose }) => {
    const editedPdfUrl = pdfUrl.replace("obigae_", "");

    const [pageHeight, setPageHeight] = useState(null);
    const [pageWidth, setPageWidth] = useState(null);
    const [elementPos, setElementPos] = useState(null);
    const [responseState, setResponseState] = useState(null);

    const [isUpdating, setIsUpdating] = useState(false);

    const onRenderSuccess = (page) => {
        const viewport = page.getViewport({ scale: 1 });
        const aspectRatio = viewport.width / viewport.height;
        // Calculate width based on window width
        const calculatedWidth = Math.min(window.innerWidth * 0.5, viewport.width);
        const calculatedHeight = calculatedWidth / aspectRatio;
        setPageHeight(calculatedHeight);
        setPageWidth(calculatedWidth);
        setElementPos({
            "x": 0,
            "y": calculatedHeight - 150,
            "width": calculatedWidth,
            "height": 150,
            "pdf_key": pdfName,
            "obi_key": obiName,
            "auto_edited_width": editViewport.width,
            "auto_edited_height": editViewport.height,
            "original_width": viewport.width,
            "original_height": viewport.height,
            "display_width": calculatedWidth,
            "display_height": calculatedHeight
        })
    };

    const onDragStop = (e, d) => {
        setElementPos(prev => ({
            ...prev,
            "x": d.x,
            "y": d.y
        }));
    };

    const onResizeStop = (e, direction, ref, delta, position) => {
        setElementPos(prev => ({
            ...prev,
            "width": parseInt(ref.style.width, 10),
            "height": parseInt(ref.style.height, 10),
            "x": position.x,
            "y": position.y,
        }));
    };

    useEffect(() => {

    }, [elementPos]);

    function showCustomAlert() {
        const alertElement = document.getElementById('customAlert');
        alertElement.style.display = 'block';
        setTimeout(() => {
            alertElement.style.display = 'none';
        }, 2000); // 3秒後にアラートを隠す
    }

    const onEdit = async () => {
        setIsUpdating(true);
        try {
            const options = {
                body: { ...elementPos, "id_token": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`}
            };
            const responseJson = await API.post('stg-call-obigae-api', '/', options);
            setResponseState(responseJson);
            showCustomAlert(); // カスタムアラートを表示
            // 3秒待ってからモーダルを閉じる
            setTimeout(() => {
                onClose(); // モーダルを閉じる
                pdfClose(); // モーダルを閉じる
            }, 2000);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsUpdating(false);
        }
    };

    // responseStateが変更されたら再レンダリングする
    useEffect(() => {

    }, [responseState]);


    return (
        <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.8)", zIndex: 1000 }}>
            <div className="container pdfscreen" style={{ width: pageWidth ? `${pageWidth}px` : 'auto', margin: "7% auto" }}>
                <div id="customAlert">
                    更新が完了しました！
                </div>
                <Document file={editedPdfUrl} style={{ pointerEvents: "none" }}>
                    <Page
                        pageNumber={1} 
                        width={pageWidth}
                        height={pageHeight}
                        onRenderSuccess={onRenderSuccess}
                    />
                </Document>
                {elementPos && 
                    <Rnd
                        default={elementPos}
                        style={{ backgroundColor: "gray", opacity: 0.5, zIndex: 1001 }}
                        onDragStop={onDragStop}
                        onResizeStop={onResizeStop}
                    >
                    </Rnd>
                }
            </div>
            <button onClick={onClose} style={{ position: "absolute", top: 0, right: 0, padding: "10px", backgroundColor: "red", color: "white" }}>戻る</button>
            <button disabled={isUpdating} onClick={onEdit} style={{ position: "absolute", bottom: 50, left: "50%", transform: "translateX(-50%)", padding: "10px", backgroundColor: "blue", color: "white", zIndex: 1002 }}>更新</button>
        </div>
    );
};

export default PdfEditDisplay;
