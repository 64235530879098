import React, { useState, useEffect } from 'react';
import { Auth, Storage, API } from 'aws-amplify';


const FileUploader = () => {
    const [pdfFile, setPdfFile] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [pdfFileName, setPdfFileName] = useState("");
    const [imageFileName, setImageFileName] = useState("");

    const handlePdfChange = async (e) => {
        const selectedFile = e.target.files[0];
        setPdfFile(selectedFile);
        setPdfFileName(selectedFile.name);
    };

    const handleImageChange = async (e) => {
        const selectedFile = e.target.files[0];
        setImageFile(selectedFile);
        setImageFileName(selectedFile.name);
    };


    useEffect(() => {
        if (pdfFile && imageFile) {
            handleSubmit();
        }
    }, [pdfFile, imageFile]);

    const executeObigaeAPI = async (pdfFileName, imageFileName) => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const cognitoId = user.attributes.sub;

            const options = {
                body: {
                    "pdf_key": pdfFileName,
                    "obi_img_name": imageFileName,
                    "id_token": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
            };

            await API.post('stg-multi-pdf-obigae', '/',options);
        } catch (error) {
            console.error("Error:", error);
            alert('PDFの処理に失敗しました。');
        }
    }

    const handleSubmit = async () => {

        setUploadSuccess(false); // 追加

        const { v4: uuidv4 } = require('uuid');
        const uniqueId = uuidv4();
        const pdfFileName = pdfFile.name.split('.')[0] + '_' + uniqueId + '.pdf'

        const extension = imageFile.name.split('.')[1]
        const imageFileName = imageFile.name.split('.')[0] + '_' + uniqueId + '.' + extension
        try {
            await Storage.put(pdfFileName, pdfFile, {
                bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
                region: 'ap-northeast-1',
                level: "private",
                contentType: pdfFile.type,
            });

            await Storage.put(imageFileName, imageFile, {
                bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
                region: 'ap-northeast-1',
                level: "private",
                contentType: imageFile.type,
            });

            setUploadSuccess(true);
            executeObigaeAPI(pdfFileName, imageFileName)  // ここでAPIを実行
        } catch (error) {
            console.error('アップロードエラー:', error);
            alert('アップロードに失敗しました。');
        }
    };

    return (
        <div className="container">
            <form className="box">
                <p>図面ファイル：pdf形式・同時に20枚まで</p>
                <div className="file has-name">
                    <label className="file-label">
                        <input
                            className="file-input"
                            type="file"
                            accept="application/pdf"
                            onChange={handlePdfChange}
                        />
                        <span className="file-cta">
                            <span className="file-label">販売図面ファイルを選択</span>
                        </span>
                        <span className="file-name">{pdfFileName}</span>
                    </label>
                </div>

                <p>帯ファイル：png形式・3MB以下のみ</p>
                <div className="file has-name">
                    <label className="file-label">
                        <input
                            className="file-input"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={handleImageChange}
                        />
                        <span className="file-cta">
                            <span className="file-label">帯ファイルを選択</span>
                        </span>
                        <span className="file-name">{imageFileName}</span>
                    </label>
                </div>

                {uploadSuccess && (
                <p className="has-text-success">アップロードが成功しました。1分ほどしてから更新ボタンを押してください。</p>
                )}
            </form>
        </div>
    );
};

export default FileUploader;
