// PdfFullscreenDisplay.js
import React, { useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import '../css/PdfDisplay.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfFullscreenDisplay = ({ pdfUrl, onClose, onNext, onPrevious, onEdit, setEditViewport }) => {
    const [pageHeight, setPageHeight] = useState(null);
    const [pageWidth, setPageWidth] = useState(null);

    const onRenderSuccess = (page) => {
        const viewport = page.getViewport({ scale: 1 });
        const aspectRatio = viewport.width / viewport.height;
        const calculatedHeight = window.innerHeight * 0.8
        const calculatedWidth = calculatedHeight * aspectRatio;
        setPageHeight(calculatedHeight);
        setPageWidth(calculatedWidth);
        setEditViewport(viewport);
    };


    return (
        <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.8)", zIndex: 1000 }}>
            <button onClick={onPrevious} style={{ position: "absolute", top: "50%", left: "5%", zIndex: 1001 }}>←</button>
            <div className="container pdfscreen" >
                <div style={{ position: "relative", margin: "5% auto" }}>
                    <Document
                        file={pdfUrl}
                    >
                        <Page 
                            pageNumber={1} 
                            width={pageWidth}
                            height={pageHeight}
                            onRenderSuccess={onRenderSuccess}
                        />
                    </Document>
                </div>
            </div>
            <button onClick={onNext} style={{ position: "absolute", top: "50%", right: "5%", zIndex: 1001 }}>→</button>
            <button onClick={onClose} style={{ position: "absolute", top: 0, right: 0, padding: "10px", backgroundColor: "red", color: "white" }}>閉じる</button>
            <button onClick={onEdit} style={{ position: "absolute", bottom: 50, left: "50%", transform: "translateX(-50%)", padding: "10px", backgroundColor: "blue", color: "white" }}>編集する</button>
        </div>
    );
};

export default PdfFullscreenDisplay;
