import React, { useState, useEffect } from 'react';
import { Auth, API } from 'aws-amplify';

import ModalDetails from './ModalDetails';



const ReadDynamoDb = () => {
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null); // この行を追加
    const [isModalOpen, setIsModalOpen] = useState(false); // この行を追加

    const handleRowClick = (item) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedItem(null);
        setIsModalOpen(false);
    };


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const options = {
                body: {
                    "id_token": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                }
            };
            const responseJson = await API.post('stg-obigae-read-dynamodb', '/', options);
            const sortedResponseJson = responseJson.sort(function(a, b) {
                return (a.created_at < b.created_at) ? 1 : -1;  //オブジェクトの昇順ソート
            });
            setData(sortedResponseJson);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div className="container">
            <h2 className="title is-2 has-text-centered has-text-grey-dark">
                登録済み図面
            </h2>
            {data.length > 0 ? (
                <table className="table is-fullwidth is-striped is-hoverable">
                    <thead className="has-background-grey-lighter">
                        <tr>
                            <th className="has-text-grey-dark">ID</th>
                            <th className="has-text-grey-dark">pdf名</th>
                            <th className="has-text-grey-dark">帯ファイル名</th>
                            <th className="has-text-grey-dark">登録日</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index} className="has-text-grey" onClick={() => handleRowClick(item)}>
                                <td>{item.id}</td>
                                <td>{item.pdf_name}</td>
                                <td>{item.obi_img_name}</td>
                                <td>{item.created_at}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className="has-text-centered has-text-grey">No data found</p>
            )}
            {isModalOpen && <ModalDetails data={selectedItem} closeModal={handleCloseModal} />} {/* この行を追加 */}
        </div>
    );
};

export default ReadDynamoDb;
