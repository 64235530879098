import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import FileUploader from './components/FileUploader';
import ReadDynamoDb from './components/ReadDynamoDb';



function AppContent({ signOut, user }) {
    let handleSignOut = (e) => {
        e.preventDefault();
        signOut();
    };

    return (
        <div id="app">
            <header className="navbar is-black">
                <div className="navbar-end">
                    <div className="navbar-item">
                        <button className="button is-white" onClick={handleSignOut}>Sign out</button>
                    </div>
                </div>
            </header>
            <main>
                <section>
                    <FileUploader />
                </section>
                <section>
                    <ReadDynamoDb />
                </section>
            </main>
        </div>
    );

}

export default withAuthenticator(AppContent, { hideSignUp: true });